<template>
  <div v-if="(this.isAdmin || this.isAccountAdmin)">
    <a-dropdown placement="bottomRight">
      <div :class="$style.dropdown">
        <span class="text-uppercase">{{ selectedClientName }}</span>
      </div>
      <a-menu slot="overlay" @click="onClientChange">
        <a-menu-item v-for='group in availableCustomerGroups' :key='group.id'>
          <span class="text-uppercase font-size-12 mr-2">{{ group.name }}</span>
        </a-menu-item>
      </a-menu>
    </a-dropdown>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex'

export default {
  computed: {
    ...mapState('ocapi', [
      'allCustomerGroups',
    ]),
    ...mapState('admin', [
      'globalClientSelected',
    ]),
    ...mapGetters('ping', [
      'isAdmin',
      'isAccountAdmin',
      'manageableAccounts',
    ]),
    selectedClientName() {
      return (this.allCustomerGroups.filter(group => group.id === this.globalClientSelected))[0].name
    },
    availableCustomerGroups() {
      return this.manageableAccounts?.length ? this.manageableAccounts : this.allCustomerGroups
    }
  },
  methods: {
    ...mapActions('admin', [
      'updateGlobalClientSelected',
    ]),
    onClientChange(item) {
      this.updateGlobalClientSelected(item.key)
      // this.$router.go(0)
      // this.$parent.$parent.$parent.$parent.$children[2].$children[0].$children[0].triggerUpdateChart()
    },
  },
}
</script>

<style lang="scss" module>
@import "./style.module.scss";
</style>
