/* eslint-disable comma-dangle */
/* eslint-disable semi */
/* eslint-disable padded-blocks */
import Vue from 'vue'
import Router from 'vue-router'
import AuthLayout from '@/layouts/Auth'
import MainLayout from '@/layouts/Main'
import Login from '@/layouts/Login.vue'
import Error from '@/layouts/Error.vue'
import store from '@/store'
import Cookies from 'js-cookie'
import clientConfig from './util/clientConfig';

function beforeProtected(to, from, next) {
  console.log('beforeProtected')
  store.commit('startLoading');

  const client = Cookies.get('client', {
    cookieDomain: store.state.ping.cookieDomain
  });

  store.dispatch('ping/setConfig', client)
    .then(() => store.dispatch('ping/checkAuthentication', to))
    .then(() => {
      if (store.state.ping.authenticated) {
        return store.dispatch('admin/resolveCustomer')
          .then(() => store.dispatch('admin/init'))
      } else {
        // missing accessToken or idToken for this individual
        console.log('No token found in URL Fragment');

        // was the user just sent here from p14c?
        if (store.state.ping.flowId) {

          // yes, url has a flowId - so they just arrived from p14c
          console.log('flowId found in url');
          console.log('Performing p14c ajax query to determine next step');
          return store.dispatch('ping/checkFlowStatus');

        } else {

          // no, url does not have a flowId.  and we don't have a session.  send them authorizationUrl
          console.log('No flowId found in url');
          console.log('Redirecting to p14c to bootstrap session');
          store.dispatch('ping/doLogin');

        }
      }
    })
    .then(() => {
      store.commit('stopLoading');
      next();
    })
    .catch(error => {
      store.commit('stopLoading');
      if (error?.locked) {
        store.dispatch('handleError', {
          title: 'Account Disabled',
          message: 'Your account has been locked out due to inactivity.  Please reach out to your client administrator to have your account unlocked.',
        });
      } else if (!store.state.ocapi.customerGroup) {
        store.dispatch('handleError', {
          title: 'Access Denied',
          message: 'You are not authorized to view this page.',
        });
      } else {
        store.dispatch('handleError', {
          title: 'Error logging in',
          message: 'An error occurred during login',
          data: error
        })
      }
    })
}

Vue.use(Router)

const router = new Router({
  base: process.env.BASE_URL,
  mode: 'history',
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    {
      path: '/login',
      component: Login,
      children: [
        {
          path: '/',
          name: 'Log in',
          component: () => import('./views/Login.vue'),
          beforeEnter: beforeProtected
        },
      ],
    },
    // create client login routes dynamically
    ...Object.keys(clientConfig).map(id => ({
      path: `/${id}`,
      component: Login,
      children: [
        {
          path: '/',
          name: `Log in (${id})`,
          component: () => import('./views/Login.vue'),
          beforeEnter: (to, from, next) => {
            Cookies.set('client', id, { cookieDomain: store.state.ping.cookieDomain });
            beforeProtected(to, from, next);
          }
        },
      ],
    })),
    {
      path: '/sso',
      component: Login,
      children: [
        {
          path: ':id',
          name: 'SSO log in (client)',
          component: () => import('./views/Login.vue'),
          beforeEnter: (to, from, next) => {
            Cookies.set('client', to.params.id, { cookieDomain: store.state.ping.cookieDomain });
            Cookies.set('autoredirect', 'true', { cookieDomain: store.state.ping.cookieDomain });
            beforeProtected(to, from, next);
          }
        },
      ]
    },
    {
      path: '/logout',
      name: 'Log out',
      beforeEnter: () => {
        store.dispatch('ping/doLogout');
      }
    },
    {
      path: '/error',
      component: Error,
      children: [
        {
          path: '/',
          name: 'Error',
          component: () => import('./views/Error.vue'),
        }
      ]
    },
    {
      path: '/',
      redirect: 'reports/Summary',
      component: MainLayout,
      meta: {
        authRequired: false,
        hidden: true,
      },
      beforeEnter: beforeProtected,
      children: [
        // Dashboards
        {
          path: '/reports/Summary',
          meta: {
            title: 'Summary',
          },
          component: () => import('./views/reports/SummaryReporting.vue'),
          beforeEnter: beforeProtected,
        },
        {
          path: '/reports/ParticipationTrend',
          meta: {
            title: 'Participation Trend',
          },
          component: () => import('./views/reports/ParticipationTrend.vue'),
        },
        {
          path: '/reports/CardsSentTrend',
          meta: {
            title: 'Cards Sent Trend',
          },
          component: () => import('./views/reports/CardsSentTrend.vue'),
        },
        {
          path: '/reports/CardPersonalizationReport',
          meta: {
            title: 'Card Personalization Report',
          },
          component: () => import('./views/reports/CardPersonalizationReport.vue'),
        },
        {
          path: '/reports/SendingCategory',
          meta: {
            title: 'Sending Category',
          },
          component: () => import('./views/reports/SendingCategory.vue'),
        },
        {
          path: '/reports/TopProducts',
          meta: {
            title: 'Top Products',
          },
          component: () => import('./views/reports/TopProducts.vue'),
        },
        {
          path: '/reports/ClientMinimum',
          meta: {
            title: 'Client Minimum',
          },
          component: () => import('./views/reports/ClientMinimum.vue'),
        },
        {
          path: '/reports/ReportingUserList',
          meta: {
            title: 'Reporting User List',
          },
          component: () => import('./views/reports/ReportingUserList.vue'),
        },
        {
          path: '/reports/UserParticipation',
          meta: {
            title: 'User Participation',
          },
          component: () => import('./views/reports/UserParticipationSummary.vue'),
        },
        {
          path: '/users',
          name: 'Manage Users',
          meta: {
            title: 'Manage Users',
          },
          component: () => import('./views/users/ManageUsers.vue'),
        },
        {
          path: '/users/new',
          name: 'New User',
          meta: {
            title: 'New User',
          },
          component: () => import('./views/users/CreateUser.vue'),
        },
        {
          path: '/users/:id',
          name: 'Edit User',
          meta: {
            title: 'Edit User',
          },
          component: () => import('./views/users/EditUser.vue'),
        },
        {
          path: '/clients',
          name: 'Manage Clients',
          meta: {
            title: 'Manage Clients',
          },
          component: () => import('./views/clients/ManageClients.vue'),
        },
        // {
        //   path: '/clients/new',
        //   name: 'New Client',
        //   meta: {
        //     title: 'New Client',
        //   },
        //   component: () => import('./views/clients/CreateClient.vue'),
        // },
        {
          path: '/clients/:id',
          name: 'Edit Client',
          meta: {
            title: 'Edit Client',
          },
          component: () => import('./views/clients/EditClient.vue'),
        },
      ],
    },

    // System Pages
    {
      path: '/auth',
      component: AuthLayout,
      redirect: 'auth/login',
      children: [
        {
          path: '/auth/404',
          meta: {
            title: 'Error 404',
          },
          component: () => import('./views/auth/404'),
        },
        {
          path: '/auth/500',
          meta: {
            title: 'Error 500',
          },
          component: () => import('./views/auth/500'),
        },
        {
          path: '/auth/login',
          meta: {
            title: 'Sign In',
          },
          component: () => import('./views/auth/login'),
        },
        {
          path: '/auth/register',
          meta: {
            title: 'Sign Up',
          },
          component: () => import('./views/auth/register'),
        },
        {
          path: '/auth/forgot-password',
          meta: {
            title: 'Forgot Password',
          },
          component: () => import('./views/auth/forgot-password'),
        },
        {
          path: '/auth/lockscreen',
          meta: {
            title: 'Lockscreen',
          },
          component: () => import('./views/auth/lockscreen'),
        },
      ],
    },

    // Redirect to 404
    {
      path: '*', redirect: 'auth/404', hidden: true,
    },
  ],
})

// router.beforeEach((to, from, next) => {
//   if (to.matched.some(record => record.meta.authRequired)) {
//     if (!store.state.user.authorized) {
//       next({
//         path: '/auth/login',
//         query: { redirect: to.fullPath },
//       })
//     } else {
//       next()
//     }
//   } else {
//     next()
//   }
// })

export default router
