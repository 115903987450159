<template>
  <!-- <a-dropdown :trigger="['click']" placement="bottomLeft" @visibleChange="addCount">
    <div :class="$style.dropdown">
      <a-badge :count="count">
        <a-avatar shape="square" icon="user" size="large" :class="$style.avatar" />
      </a-badge>
    </div> -->
  <a-dropdown placement="bottomLeft">
    <div :class="$style.dropdown">
      <a-avatar shape="square" icon="user" size="large" :class="$style.avatar" />
    </div>
    <a-menu slot="overlay">
      <a-menu-item>
        <div>
          <strong>{{ $t('topBar.profileMenu.hello') }}, {{ this.parsedIdToken.given_name || 'Anonymous' }}</strong>
        </div>
      </a-menu-item>
      <a-menu-divider />
      <a-menu-item>
        <div>
          <strong class="mr-1">{{ $t('topBar.profileMenu.email') }}:</strong>
          {{ this.parsedIdToken.email || '—' }}
        </div>
      </a-menu-item>
      <a-menu-divider  v-if="this.isAdmin || isAccountAdmin || this.isClientAdmin" />
      <a-menu-item v-if="this.isAdmin || isAccountAdmin || this.isClientAdmin">
        <router-link :to="{path:'/users/'+this.parsedAccessToken.sub}">
          <i class="fe fe-user mr-2"></i>
          {{ $t('topBar.profileMenu.editProfile') }}
        </router-link>
      </a-menu-item>
      <a-menu-divider />
      <a-menu-item>
        <router-link to="/logout">
          <i class="fe fe-log-out mr-2"></i>
          {{ $t('topBar.profileMenu.logout') }}
        </router-link>
      </a-menu-item>
    </a-menu>
  </a-dropdown>
</template>

<script>
import { mapState, mapGetters } from 'vuex'

export default {
  computed: {
    ...mapState(['user']),
    ...mapGetters('ping', [
      'parsedIdToken',
      'isAdmin',
      'isAccountAdmin',
      'isClientAdmin',
      'parsedAccessToken'
    ]),
    loading() {
      return this.$store.state.user.loading
    },
  },
  data: function () {
    return {
      count: 7,
    }
  },
  methods: {
    addCount() {
      this.count++
    },
    logout() {
      this.$store.dispatch('user/LOGOUT')
    },
  },
}
</script>

<style lang="scss" module>
@import "./style.module.scss";
</style>
