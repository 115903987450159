export default {
  abcbs: {
    logo: 'abcbs.png',
    background_image: 'background-hbc.jpg',
    primary_color: '#003050',
    primary_color_hover: '#001320',
    clientName: 'Arkansas Blue Cross Blue Shield',
  },
  ally: {
    clientName: 'Ally Financial ',
    logo: 'hbc-logo-black-medium.png',
    background_image: 'background-hbc.jpg',
    primary_color: '#003050',
  },
  aquarion: {
    logo: 'Aquarion.png',
    clientName: 'Aquarion Water Company',
    background_image: 'background.jpg',
    primary_color: '#00457C',
    primary_color_hover: '#14a2bc',
  },
  atriohp: {
    clientName: 'Atrio',
    logo: 'atrio.png',
    background_image: 'background.jpg',
    primary_color: '#509e2f',
  },
  bcbsaz: {
    logo: 'bcbsaz.png',
    background_image: 'background.jpg',
    primary_color: '#0088D0',
    primary_color_hover: '#0088D0',
    clientName: 'Blue Cross Blue Shield',
    policy: 'BCBSAZ',
  },
  carecredit: {
    logo: 'CareCredit_logo.png',
    clientName: 'Care Credit',
    background_image: 'background.jpg',
    primary_color: '#3E3D3D',
  },
  caredemo: {
    logo: 'LargeYLHLogoG.png',
    background_image: 'background.jpg',
    primary_color: '#3E3D3D',
    primary_color_hover: '#313030',
    clientName: 'Care Demo',
  },
  cityutilities: {
    logo: 'CityUtilities.png',
    clientName: 'City Utilities',
    background_image: 'background2.jpg',
    primary_color: '#008789',
    primary_color_hover: '#292929',
  },
  comcast: {
    logo: 'xfinity.png',
    clientName: 'Comcast',
    background_image: 'background.jpg',
    primary_color: '#000000',
    primary_color_hover: '#000000',
  },
  consumersenergy: {
    logo: 'CE_Logo.png',
    clientName: 'Consumers Energy',
    background_image: 'background.jpg',
    primary_color: '#097ABF',
    primary_color_hover: '#086aa7',
  },
  evergy: {
    logo: 'Evergy.png',
    clientName: 'NiSource',
    background_image: 'background.jpg',
    primary_color: '#3E3D3D',
    policy: 'Evergy',
  },
  hbc: {
    logo: 'hbc-logo-black-medium.png',
    clientName: 'Hallmark Business Connections',
    background_image: 'background-hbc.jpg',
    primary_color: '#3E3D3D'
  },
  thehealthplan: {
    logo: 'thehealthplan.png',
    clientName: 'The Health Plan',
    primary_color: '#009a49',
    primary_color_hover: '#006716',
  },
  hmkconsumercare: {
    clientName: 'HMK Consumer Care',
    logo: 'HMKcsmr_Standard_plum.png',
    background_image: 'background.jpg',
    primary_color: '#3E3D3D',
  },
  hmkcustomercare: {
    logo: 'HMKcsmr_Standard_plum.png',
    background_image: 'background2.jpg',
    clientName: 'HMK Customer Care',
    primary_color: '#3e3772',
    primary_color_hover: '#352f61',
  },
  horizonbraven: {
    logo: 'HMKcsmr_Standard_plum.png',
    background_image: 'background2.jpg',
    clientName: 'Braven/Horizon DSNP',
    primary_color: '#3e3772',
    primary_color_hover: '#352f61',
  },
  icwgroup: {
    logo: 'ICW.png',
    clientName: ' ICW Group Hallmark Experience',
    background_image: 'background-hbc.jpg',
    primary_color: '#009BDF',
    primary_color_hover: '#007cb2',
  },
  idahopower: {
    logo: 'hbc_blk_280x96.png',
    clientName: 'Idaho Power',
    background_image: 'background2.jpg',
    primary_color: '#008789',
    primary_color_hover: '#006e70',
    policy: 'IdahoPower',
  },
  liberty: {
    logo: 'LibertyUtilities.png',
    clientName: 'Liberty Utilities',
    background_image: 'background.jpg',
    primary_color: '#3E3D3D',
    primary_color_hover: '#313030',
  },
  nisource: {
    logo: 'NiSource.png',
    clientName: 'NiSource',
    background_image: 'background2.jpg',
    primary_color: '#007cc3',
    primary_color_hover: '#006caa',
    policy: 'NiSource',
  },
  paramount: {
    logo: 'ParamountPromedica.png',
    clientName: 'Paramount',
    background_image: 'background.jpg',
    primary_color: '#009B3A',
  },
  paramountdental: {
    logo: 'ParamountDental.png',
    clientName: 'Paramount Dental',
    background_image: 'background.jpg',
    primary_color: '#009B3A',
  },
  peco: {
    logo: 'PECO.png',
    clientName: 'Peco',
    background_image: 'background.jpg',
    primary_color: '#0077bb',
    primary_color_hover: '#0067a2',
  },
  ppl: {
    logo: 'PPLlogin.png',
    clientName: 'PPL',
    background_image: 'background.jpg',
    primary_color: '#003399',
    primary_color_hover: '#002b80',
  },
  rpu: {
    logo: 'RPU_Horizontal.png',
    clientName: 'RPU',
    background_image: 'background.jpg',
    primary_color: '#228ec2',
    primary_color_hover: '#006990',
  },
  spire: {
    logo: 'Spire_logo.png',
    background_image: 'background.jpg',
    clientName: 'Spire',
    primary_color: '#E87322',
    primary_color_hover: '#da6717',
  },
  srp: {
    logo: 'SRP_logo.png',
    background_image: 'background.jpg',
    clientName: 'Salt River Project',
    primary_color: '#00599c',
    primary_color_hover: '#004a83',
    policy: 'SRP',
  },
  tmwa: {
    logo: 'tmwa.png',
    background_image: 'background-hbc.jpg',
    clientName: 'Truckee Meadows Water Authority',
    primary_color: '#abbd26',
    primary_color_hover: '#444c0f',
  },
  idpinit: {
    clientName: 'Hallmark Business Connections',
    logo: 'hbc-logo-black-medium.png',
    background_image: 'background.jpg',
    primary_color: '#3E3D3D',
    policy: 'IDPInit',
  },
  idpinitstage: {
    clientName: 'Hallmark Business Connections',
    logo: 'hbc-logo-black-medium.png',
    background_image: 'background.jpg',
    primary_color: '#3E3D3D',
    policy: 'IDPInit-Stage',
  },
};
