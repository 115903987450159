/* eslint-disable quote-props */
/* eslint-disable eqeqeq */
/* eslint-disable padded-blocks */
/* eslint-disable no-console */
/* eslint-disable comma-dangle */
/* eslint-disable quotes */
/* eslint-disable semi */
import clientConfig from '../util/clientConfig';
import Cookies from 'js-cookie';
import generateNonce from '../util/generateNonce';
import axios from 'axios';
axios.defaults.withCredentials = true;
const transformRequest = (jsonData = {}) => Object.entries(jsonData)
  .map(x => `${encodeURIComponent(x[0])}=${encodeURIComponent(x[1])}`)
  .join('&');

const salesforceUserDataSchema = {
  favorites: [],
  languages: [],
  sender_profiles: []
};
const profileSchema = {
  employeeID: '',
  supervisorID: '',
  location: '',
  role: '',
  managingAccounts: [],
  reportingUsers: []
};

const mockRecipientJSON = {
  shipping_address: {
    address1: '121 South 8th Street',
    city: 'Minneapolis',
    company_name: 'Hallmark Business Connections',
    country_code: 'US',
    first_name: 'Will',
    last_name: 'Anderson',
    phone: '6127089062',
    postal_code: '55402',
    state_code: 'MN'
  },
};

export default {
  namespaced: true,
  state: {
    siteEnvironment: process.env.VUE_APP_ENV,
    authUrl: process.env.VUE_APP_PINGONE_AUTH_URL,
    apiUrl: process.env.VUE_APP_PINGONE_API_URL,
    environmentId: process.env.VUE_APP_PINGONE_ENVIRONMENT_ID,
    clientId: process.env.VUE_APP_PINGONE_CLIENT_ID,
    clientSecret: process.env.VUE_APP_PINGONE_CLIENT_SECRET,
    cookieDomain: process.env.VUE_APP_PINGONE_COOKIE_DOMAIN,
    landingUrl: process.env.VUE_APP_LANDING_URL,
    loginUrl: process.env.VUE_APP_LOGIN_URL,
    authenticated: false,
    flowId: null,
    flowInfo: {},
    passwordStatus: null,
    userTokens: null,
    recipientJSON: mockRecipientJSON,
    userInfo: null,
    username: '',
    accountLinkUsername: '',
    password: '',
    newPassword: '',
    accountRegPassword: '',
    warningTitle: '',
    warningMessage: '',
    selectableDevices: [],
    devicePushMessage: '',
    socialProviders: [],
    validatePasswordUrl: '',
    validateIdentifierUrl: '',
    userVerifyUrl: '',
    validateOtpUrl: '',
    changePasswordUrl: '',
    passwordRecoverUrl: '',
    deviceSelectUrl: '',
    accountLinkUrl: '',
    passwordForgotUrl: '',
    registerUrl: '',
    passwordRecoverCode: '',
    view: 'DEFAULT',
    loading: false,
    config: {},
    IDAUsers: [],
    roleAssignments: [],
    roles: [],
    populations: [],
    loginErrors: [],
    newUserId: '',
    newUserTemporaryPassword: '',
    tempUserBatch: [],
  },
  mutations: {
    setAuthenticated(state, payload) {
      state.authenticated = payload;
    },
    setAccountLinkUsername(state, payload) {
      state.accountLinkUsername = payload;
    },
    setFlowId(state, payload) {
      state.flowId = payload;
    },
    setFlowInfo(state, payload) {
      state.flowInfo = payload;
    },
    setPasswordStatus(state, payload) {
      state.passwordStatus = payload;
    },
    setUserTokens(state, payload) {
      state.userTokens = payload;
    },
    setUserInfo(state, payload) {
      state.userInfo = payload;
    },
    setUsername(state, payload) {
      state.username = payload;
    },
    setPassword(state, payload) {
      state.password = payload;
    },
    setWarningTitle(state, payload) {
      state.warningTitle = payload;
    },
    setWarningMessage(state, payload) {
      state.warningMessage = payload;
    },
    setSelectableDevices(state, payload) {
      state.selectableDevices = payload;
    },
    setDevicePushMessage(state, payload) {
      state.devicePushMessage = payload;
    },
    setSocialProviders(state, payload) {
      state.socialProviders = payload;
    },
    setValidatePasswordUrl(state, payload) {
      state.validatePasswordUrl = payload;
    },
    setValidateIdentifierUrl(state, payload) {
      state.validateIdentifierUrl = payload;
    },
    setUserVerifyUrl(state, payload) {
      state.userVerifyUrl = payload;
    },
    setValidateOtpUrl(state, payload) {
      state.validateOtpUrl = payload;
    },
    setChangePasswordUrl(state, payload) {
      state.changePasswordUrl = payload;
    },
    setPasswordRecoverUrl(state, payload) {
      state.passwordRecoverUrl = payload;
    },
    setDeviceSelectUrl(state, payload) {
      state.deviceSelectUrl = payload;
    },
    setAccountLinkUrl(state, payload) {
      state.accountLinkUrl = payload;
    },
    setPasswordForgotUrl(state, payload) {
      state.passwordForgotUrl = payload;
    },
    setRegisterUrl(state, payload) {
      state.registerUrl = payload;
    },
    setPasswordRecoverCode(state, payload) {
      state.passwordRecoverCode = payload;
    },
    setView(state, payload) {
      state.view = payload;
    },
    startLoading(state) {
      state.loading = true;
    },
    stopLoading(state) {
      state.loading = false;
    },
    setConfig(state, payload) {
      state.config = payload;
    },
    setIDAUsers(state, payload) {
      state.IDAUsers = payload;
    },
    setRoleAssignments(state, payload) {
      state.roleAssignments = payload;
    },
    setRoles(state, payload) {
      state.roles = payload;
    },
    setPopulations(state, payload) {
      state.populations = payload;
    },
    setLoginErrors(state, payload) {
      state.loginErrors = payload;
    },
    setNewUserId(state, payload) {
      state.newUserId = payload;
    },
    setNewUserTemporaryPassword(state, payload) {
      state.newUserTemporaryPassword = payload;
    },
    setTempUserBatch(state, payload) {
      state.tempUserBatch = payload;
    }
  },
  actions: {
    toggleFavorite({ state, commit, dispatch }, payload) {
      const id = payload;
      const favorites = [...state.salesforceUserData.favorites];
      const newSalesforceUserData = Object.assign({}, state.salesforceUserData);
      const index = favorites.indexOf(id);
      let newFavorites = []
      if (index === -1) {
        newFavorites = [...favorites, id];
      } else {
        newFavorites = [
          ...favorites.slice(0, index),
          ...favorites.slice(index + 1),
        ];
      }
      newSalesforceUserData.favorites = Object.freeze(newFavorites);
      commit('setSalesforceUserData', newSalesforceUserData);
      return dispatch('updateSalesforceUserData', state.salesforceUserData);
    },
    setConfig({ commit }, payload) {
      return new Promise((resolve) => {
        if (payload && payload in clientConfig) {
          commit('setConfig', clientConfig[payload]);
        } else {
          commit('setConfig', clientConfig.hbc);
        }
        resolve();
      });
    },
    doLogin({ state, getters }) {
      const nonce = generateNonce(60);
      const params = {
        response_type: 'code',
        client_id: state.clientId,
        redirect_uri: state.landingUrl,
        nonce: nonce,
        scope: 'openid profile address phone email p1:read:user p1:update:user',
        acr_values: getters.policy,
        post_logout_redirect_uri: state.loginUrl
      };
      const queryString = Object.keys(params)
        .map(key => `${encodeURIComponent(key)}=${encodeURIComponent(params[key])}`)
        .join('&');
      const authorizationUrl = `${state.authUrl}as/authorize?${queryString}`;

      Cookies.set('nonce', nonce, { domain: state.cookieDomain });

      window.location.href = authorizationUrl;
    },
    doLogout({ state }) {
      const idToken = Cookies.get('idToken');
      const accessToken = Cookies.get('accessToken');

      if (!idToken || !accessToken) {
        console.log('Unable to find a session for this user');

        window.location.replace(state.landingUrl);
      } else {
        console.log('idToken: ' + idToken);

        Cookies.remove('idToken', { domain: state.cookieDomain });
        Cookies.remove('accessToken', { domain: state.cookieDomain });
        Cookies.remove('refreshToken', { domain: state.cookieDomain });
        Cookies.remove('uuid', { domain: state.cookieDomain });
        Cookies.remove('nonce', { domain: state.cookieDomain });
        Cookies.remove('autoredirect', { domain: state.cookieDomain });

        const logoffUrl = `${state.authUrl}as/signoff?id_token_hint=${idToken}&post_logout_redirect_uri=${encodeURIComponent(state.loginUrl)}`;

        window.location.replace(logoffUrl);
      }
    },
    doStep({ commit, dispatch }, payload) {
      commit('startLoading');
      return new Promise((resolve, reject) => {
        axios({
          method: payload.method || 'post',
          url: payload.url,
          headers: {
            'Content-Type': payload.contentType
          },
          data: payload.data,
          withCredentials: true
        })
          .then(response => {
            commit('setFlowInfo', response);
            return dispatch('nextStep');
          })
          .then(response => {
            commit('setLoginErrors', []);
            commit('stopLoading');
            resolve(response);
          })
          .catch(error => {
            let loginErrors = error?.response?.data?.details || [];
            for (var i in loginErrors) {
              var innerErrors = [];
              const innerErrorList = loginErrors[i].innerError;
              for (var j in innerErrorList?.unsatisfiedRequirements) {
                innerErrors.push(innerErrorList[innerErrorList?.unsatisfiedRequirements[j]]);
              }
              loginErrors[i].innerError = innerErrors;
            }
            if (!loginErrors.length) {
              loginErrors = ['An unexpected problem occurred. Please try again or contact support.']
            }
            commit('setLoginErrors', loginErrors)
            commit('stopLoading');
            reject(error);
          })
      })
    },
    nextStep({ state, dispatch, commit }) {
      const { data } = state.flowInfo;
      const { status } = data;
      console.log('Parsing json to determine next step: ' + status);

      this.warningTitle = '';
      this.warningMessage = '';
      return new Promise((resolve, reject) => {
        switch (status) {
          case 'USERNAME_PASSWORD_REQUIRED':
            console.log('Rendering login form');
            commit('setValidatePasswordUrl', data._links['usernamePassword.check'].href);
            break;
          case 'PASSWORD_REQUIRED':
            console.log('Rendering login form');
            commit('setValidatePasswordUrl', data._links['usernamePassword.check'].href);
            break;
          case 'SIGN_ON_REQUIRED':
            console.log('Rendering identifier form');
            commit('setValidateIdentifierUrl', data._links['user.lookup'].href);
            break;
          case 'VERIFICATION_CODE_REQUIRED':
            console.log('Rendering verification form');
            commit('setUserVerifyUrl', data._links['user.verify'].href);
            break;
          case 'OTP_REQUIRED':
            console.log('Rendering otp form');
            commit('setValidateOtpUrl', data._links['otp.check'].href);
            break;
          case 'EXTERNAL_AUTHENTICATION_REQUIRED':
            console.log('Redirecting to external auth url');
            window.location.replace(data._embedded.identityProvider._links.authenticate.href);
            break;
          case 'MUST_CHANGE_PASSWORD':
            console.log('Rendering password form');
            commit('setChangePasswordUrl', data._links['password.reset'].href);
            break;
          case 'RECOVERY_CODE_REQUIRED':
            console.log('Rendering account recovery form');
            commit('setPasswordRecoverUrl', data._links['password.recover'].href);
            break;
          case 'DEVICE_SELECTION_REQUIRED':
            console.log('Rendering device selection');
            commit('setDeviceSelectUrl', data._links['device.select'].href);
            commit('setSelectabledevices', data._embedded.devices);
            break;
          case 'ACCOUNT_LINKING_REQUIRED':
            console.log('Rendering account linking form');
            commit('setAccountLinkUrl', data._links['user.register'].href);
            commit('setAccountLinkUsername', data.attributes.username);
            break;
          case 'PUSH_CONFIRMATION_REQUIRED':
            console.log('Waiting for user to approve push');
            for (let i = 0; i < data._embedded.devices.length; i++) {
              if (data._embedded.devices[i].id == data.selectedDevice.id) {
                commit('setDevicePushMessage', `Approval request sent to ${data._embedded.devices[i].model.marketingName}`);
              }
            }
            dispatch('getPushStatus', data._links.self.href)
              .then(resolve)
              .catch(reject);
            break;
          case 'PUSH_CONFIRMATION_TIMED_OUT':
            console.log('Push confirmation timed out');
            commit('setWarningTitle', 'Authentication failure');
            commit('setWarningMessage', 'Device approval timed out');
            break;
          case 'COMPLETED':
            console.log('Completed authentication successfully');
            console.log('Redirecting user');
            window.location.replace(data.resumeUrl);
            break;
          case 'FAILED':
            console.log('Authentication flow failure');
            commit('setWarningTitle', 'Unrecoverable error');
            commit('setWarningMessage', data.error.message);
            break;
          default:
            console.log('Unexpected outcome');
            break;
        }

        // check for enabled external identity providers

        if (data._embedded.socialProviders) {

          // yes we have external identity providers

          console.log('Social providers present');
          if (Cookies.get('autoredirect', { cookieDomain: state.cookieDomain }) === 'true') {
            console.log(data._embedded.socialProviders[0]._links.authenticate.href);
            window.location.replace(data._embedded.socialProviders[0]._links.authenticate.href);
          }
          commit('setSocialProviders', data._embedded.socialProviders);

        }

        // check for account registration is enabled

        // if (data._links['user.register'].href) {

        if ('user.register' in data._links) {

          // yes the authentication policy has registration enabled

          console.log('Account registration is enabled');
          commit('setRegisterUrl', data._links['user.register'].href);

          // create a registration button

          // newButton = '<a href="#" onclick="showRegistration();" class="btn btn-secondary btn-lg mb-2 mr-1">Create Account</a>';
          // $('#selfServicePayload').append(newButton);

        }

        // check for account recovery is enabled

        if ('password.forgot' in data._links) {

          // yes account recovery is enabled

          console.log('Account recovery is enabled');
          commit('setPasswordForgotUrl', data._links['password.forgot'].href);

          // create a forgot password button

          // newButton = '<a href="#" onclick="showPasswordForgot();" class="btn btn-secondary btn-lg mb-2 mr-1">Forgot Password</a>';
          // $('#selfServicePayload').append(newButton);

        }

        resolve();
      });

    },
    exchangeCodeForToken({ state }, payload) {
      return new Promise((resolve, reject) => {
        axios({
          method: 'post',
          url: `as/token`,
          baseURL: state.authUrl,
          auth: {
            username: state.clientId,
            password: state.clientSecret
          },
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
          },
          withCredentials: false,
          transformRequest: jsonData => transformRequest(jsonData),
          data: {
            grant_type: 'authorization_code',
            code: payload,
            redirect_uri: state.landingUrl
          }
        })
          .then(resolve)
          .catch(reject);
      });
    },
    exchangeRefreshToken({ state, commit, dispatch }) {
      return new Promise((resolve, reject) => {
        axios({
          method: 'post',
          url: `as/token`,
          baseURL: state.authUrl,
          auth: {
            username: state.clientId,
            password: state.clientSecret
          },
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
          },
          withCredentials: false,
          transformRequest: jsonData => transformRequest(jsonData),
          data: {
            grant_type: 'refresh_token',
            refresh_token: state.userTokens.refresh_token,
          }
        })
          .then(response => {
            Cookies.set('accessToken', response.data.access_token, { domain: state.cookieDomain });
            Cookies.set('idToken', response.data.id_token, { domain: state.cookieDomain });
            Cookies.set('refreshToken', response.data.refresh_token, { domain: state.cookieDomain });
            commit('setUserTokens', response.data);
            resolve(response);
          })
          .catch(error => {
            dispatch('doLogout');
            reject(error);
          });
      });
    },
    evaluateTokens({ getters, dispatch }) {
      return new Promise((resolve, reject) => {
        const expiration = new Date(getters.parsedAccessToken.exp * 1000);
        const now = new Date();
        if (expiration.getTime() > now.getTime()) {
          // tokens are valid
          resolve();
        } else {
          // refresh tokens
          dispatch('exchangeRefreshToken')
            .then(resolve)
            .catch(reject);
        }
      });
    },
    tokenIntrospection({ state }, payload) {
      return new Promise((resolve, reject) => {
        axios({
          method: 'post',
          url: `as/introspect`,
          baseURL: state.authUrl,
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
          },
          withCredentials: false,
          transformRequest: jsonData => transformRequest(jsonData),
          data: {
            token: payload,
            client_id: state.clientId,
            client_secret: state.clientSecret
          }
        })
          .then(resolve)
          .catch(reject);
      });
    },
    readUser({ state, getters }) {
      return new Promise((resolve, reject) => {
        axios({
          method: 'get',
          url: `/environments/${state.environmentId}/users/${getters.parsedAccessToken.sub}`,
          baseURL: state.apiUrl,
          headers: {
            'Authorization': `Bearer ${state.userTokens.access_token}`,
            'Content-Type': 'application/json',
          },
          withCredentials: false
        })
          .then(resolve)
          .catch(reject);
      });
    },
    createUser({ state }, payload) {
      return new Promise((resolve, reject) => {
        axios({
          method: 'post',
          url: `/environments/${state.environmentId}/users`,
          baseURL: state.apiUrl,
          headers: {
            'Authorization': `Bearer ${state.userTokens.access_token}`,
            'Content-Type': 'application/vnd.pingidentity.user.import+json',
            // 'Content-Type': 'application/json',
          },
          data: {
            ...payload.data
          },
          withCredentials: false
        })
          .then(resolve)
          .catch(reject);
      });
    },
    updateUser({ state }, payload) {
      return new Promise((resolve, reject) => {
        axios({
          method: 'patch',
          url: `/environments/${state.environmentId}/users/${payload.userId}`,
          baseURL: state.apiUrl,
          headers: {
            'Authorization': `Bearer ${state.userTokens.access_token}`,
            'Content-Type': 'application/json',
          },
          data: {
            ...payload.data
          },
          withCredentials: false
        })
          .then(resolve)
          .catch(reject);
      });
    },
    toggleUserEnabled({ state }, payload) {
      return new Promise((resolve, reject) => {
        axios({
          method: 'put',
          url: `/environments/${state.environmentId}/users/${payload.userId}/enabled`,
          baseURL: state.apiUrl,
          headers: {
            'Authorization': `Bearer ${state.userTokens.access_token}`,
            'Content-Type': 'application/json',
          },
          data: {
            ...payload.data
          },
          withCredentials: false
        })
          .then(resolve)
          .catch(reject);
      });
    },
    deleteUser({ state }, payload) {
      return new Promise((resolve, reject) => {
        axios({
          method: 'delete',
          url: `/environments/${state.environmentId}/users/${payload}`,
          baseURL: state.apiUrl,
          headers: {
            'Authorization': `Bearer ${state.userTokens.access_token}`,
          },
          withCredentials: false
        })
          .then(resolve)
          .catch(reject);
      })
    },
    updateCurrentUser({ dispatch, getters }, payload) {
      return dispatch('updateUser', {
        userId: getters.parsedAccessToken?.sub,
        data: {
          ...payload
        }
      })
        .then(() => dispatch('exchangeRefreshToken'))
    },
    updateSalesforceUserData({ dispatch }, payload) {
      return dispatch('updateCurrentUser', {
        salesforceUserData: payload
      });
    },
    updateProfile({ dispatch }, payload) {
      return dispatch('updateUser', {
        profile: payload
      })
    },
    lockUser({ commit, dispatch, getters }) {
      return dispatch('updateUser', {
        userId: getters.parsedAccessToken?.sub,
        data: {
          locked: {
            "status": true,
            "lockedOn": new Date()
          }
        }
      })
      /* .then(() => {
        commit('setLoginErrors', ['Your account has been locked out due to inactivity.  Please reach out to your client administrator to have your account unlocked.'])
        return dispatch('doLogout')
      }) */
    },
    changePassword({ dispatch, state }, payload) {
      console.log('changePassword called');
      return dispatch('doStep', {
        url: state.changePasswordUrl,
        contentType: 'application/vnd.pingidentity.password.reset+json',
        data: payload
      });
    },
    passwordReset({ dispatch, state }, payload) {
      return new Promise((resolve, reject) => {
        axios({
          method: 'put',
          url: `/environments/${state.environmentId}/users/${payload.userId}/password`,
          baseURL: state.apiUrl,
          headers: {
            'Authorization': `Bearer ${state.userTokens.access_token}`,
            'Content-Type': 'application/vnd.pingidentity.password.reset+json'
          },
          withCredentials: false,
          data: {
            newPassword: payload.password
          }
        })
          .then(resolve)
          .catch(reject);
      })
    },
    deviceSelect({ dispatch, state }, payload) {
      console.log('deviceSelect called');
      return dispatch('doStep', {
        url: state.deviceSelectUrl,
        contentType: 'application/vnd.pingidentity.device.select+json',
        data: payload
      });
    },
    getPushStatus({ dispatch }, payload) {
      console.log('getPushStatus called');
      return new Promise((resolve, reject) => {
        setTimeout(() => {
          dispatch('doStep', {
            method: 'get',
            url: payload,
            contentType: 'application/json'
          })
            .then(resolve)
            .catch(reject)
        }, 2000);
      });
    },
    validatePassword({ dispatch, state }, payload) {
      console.log('validatePassword called');
      return dispatch('doStep', {
        url: state.validatePasswordUrl,
        contentType: 'application/vnd.pingidentity.usernamePassword.check+json',
        data: payload
      });
    },
    validateIdentifier({ dispatch, state }, payload) {
      console.log('validateIdentifier called');
      return dispatch('doStep', {
        url: state.validateIdentifierUrl,
        contentType: 'application/vnd.pingidentity.user.lookup+json',
        data: payload
      });
    },
    validateOtp({ dispatch, state }, payload) {
      console.log('validateOtp called');
      return dispatch('doStep', {
        url: state.validateOtpUrl,
        contentType: 'application/vnd.pingidentity.otp.check+json',
        data: payload
      });
    },
    registerAccount({ dispatch, state }, payload) {
      console.log('registerAccount called');
      return dispatch('doStep', {
        url: state.registerUrl,
        contentType: 'application/vnd.pingidentity.user.register+json',
        data: payload
      });
    },
    passwordForgot({ dispatch, commit, state }, payload) {
      console.log('passwordForgot called');
      return new Promise((resolve, reject) => {
        dispatch('doStep', {
          url: state.passwordForgotUrl,
          contentType: 'application/vnd.pingidentity.password.forgot+json',
          data: payload
        })
          .then(() => {
            commit('setView', 'DEFAULT')
            resolve();
          })
          .catch(reject);
      });
    },
    passwordRecover({ dispatch, state }, payload) {
      console.log('passwordRecover called');
      return dispatch('doStep', {
        url: state.passwordRecoverUrl,
        contentType: 'application/vnd.pingidentity.password.recover+json',
        data: payload
      });
    },
    accountLink({ dispatch, state }, payload) {
      console.log('accountLink called');
      return dispatch('doStep', {
        url: state.accountLinkUrl,
        contentType: 'application/vnd.pingidentity.user.register+json',
        data: payload
      })
    },
    userVerify({ dispatch, state }, payload) {
      console.log('userVerify called');
      return dispatch('doStep', {
        url: state.userVerifyUrl,
        contentType: 'application/vnd.pingidentity.user.verify+json',
        data: payload
      })
    },
    checkFlowStatus({ dispatch, state }) {
      console.log('checkFlowStatus called');
      return dispatch('doStep', {
        method: 'get',
        url: `${state.authUrl}flows/${state.flowId}`,
        contentType: 'application/json'
      })
    },
    checkAuthentication({ state, commit, getters, dispatch }, payload) {
      return new Promise((resolve, reject) => {
        commit('setFlowId', payload.query.flowId);
        console.log("Page ready function");

        // determine if user is already signed in, and show appropriate login/logout buttons

        // renderButtonState();

        // do I already have a session cookie?

        if (Cookies.get('accessToken', { domain: state.cookieDomain }) && Cookies.get('uuid', { domain: state.cookieDomain })) {

          // yes, user already has a session

          console.log('Existing session found.  Redirecting to landingUrl');

          // redirect to the landingUrl

          // window.location.replace(landingUrl);

          commit('setUserTokens', {
            access_token: Cookies.get('accessToken', { domain: state.cookieDomain }),
            id_token: Cookies.get('idToken', { domain: state.cookieDomain }),
            refresh_token: Cookies.get('refreshToken', { domain: state.cookieDomain })
          });

          dispatch('evaluateTokens')
            .then(() => {
              if (getters.parsedIdToken?.userData) {
                return Promise.resolve();
              } else {
                return dispatch('updateSalesforceUserData', salesforceUserDataSchema)
              }
            })
            .then(() => {
              if (getters.parsedIdToken?.profile) {
                return Promise.resolve();
              } else {
                return dispatch('updateProfile', profileSchema)
              }
            })
            .then(() => {
              commit('setAuthenticated', true);
              resolve();
            })
            .catch(reject)

        } else {

          // no, user does not already have a session

          console.log('Session not found');

          // parse the url fragment for any access token

          console.log('Parsing URL Fragment for tokens');

          console.log(payload);

          const querystring = `?${payload.hash.replace('#', '')}`;
          const params = new URLSearchParams(querystring);
          const userTokens = {};
          params.forEach((value, key) => {
            if (key === 'expires_in') {
              const now = new Date();
              userTokens.expiration = now.getTime() + value;
            } else {
              userTokens[key] = value;
            }
          });
          commit('setUserTokens', userTokens);

          // is there something in the URL fragment?

          const accessToken = state.userTokens.access_token;
          const idToken = state.userTokens.id_token;
          const refreshToken = state.userTokens.refresh_token;

          console.log({ userTokens });
          if (payload.query?.code) {
            console.log({ code: payload.query.code });
            dispatch('exchangeCodeForToken', payload.query.code)
              .then(response => {
                commit('setUserTokens', response.data);
                Cookies.set('uuid', getters.parsedIdToken.sub, { domain: state.cookieDomain });
                Cookies.set('accessToken', response.data.access_token, { domain: state.cookieDomain });
                Cookies.set('idToken', response.data.id_token, { domain: state.cookieDomain });
                Cookies.set('refreshToken', response.data.refresh_token, { domain: state.cookieDomain });
                console.log(response);
                commit('setAuthenticated', true);
                resolve();
              })
              .catch(reject);
          } else if (accessToken && idToken && refreshToken) {

            // yes, we got an access token from the url fragment

            console.log('Access token received from URL fragment');

            // parse id token for subject and other details

            const idPayload = getters.parsedIdToken;

            // does requested nonce match returned nonce?

            const returnedNonce = idPayload.nonce;

            // let requestedNonce = window.localStorage.getItem('nonce');

            const requestedNonce = Cookies.get('nonce', { domain: state.cookieDomain });

            if (returnedNonce == requestedNonce) {

              // yes, nonce matches

              Cookies.set('uuid', idPayload.sub, { domain: state.cookieDomain });
              Cookies.set('accessToken', accessToken, { domain: state.cookieDomain });
              Cookies.set('idToken', idToken, { domain: state.cookieDomain });
              Cookies.set('refreshToken', refreshToken, { domain: state.cookieDomain });

              dispatch('evaluateTokens')
                .then(() => {
                  if (getters.parsedIdToken?.userData) {
                    return Promise.resolve();
                  } else {
                    return dispatch('updateSalesforceUserData', salesforceUserDataSchema);
                  }
                })
                .then(() => {
                  if (getters.parsedIdToken?.profile) {
                    return Promise.resolve();
                  } else {
                    return dispatch('updateProfile', profileSchema);
                  }
                })
                .then(() => {
                  commit('setAuthenticated', true);
                  resolve();
                })
                .catch(reject);

            } else {

              // no, nonce does not match

              console.log('Nonce validation failed');

              console.log('Requested nonce: ' + requestedNonce);
              console.log('Returned nonce: ' + returnedNonce);

              commit('setWarningTitle', 'Nonce mismatch');
              commit('setWarningMessage', 'Unexpected nonce returned in id token');

              commit('setAuthenticated', false);
              resolve();
            }

          } else {

            commit('setAuthenticated', false);
            resolve();

          }

        }
      });
    },
    fetchUser({ commit, state }, payload) {
      return new Promise((resolve, reject) => {
        axios({
          method: 'get',
          url: `/environments/${state.environmentId}/users/${payload}?expand=population`,
          baseURL: state.apiUrl,
          headers: {
            'Authorization': `Bearer ${state.userTokens.access_token}`,
            'Content-Type': 'application/json',
          },
          withCredentials: false
        })
          .then(resolve)
          .catch(reject);
      })
    },
    fetchCurrentUserRoleAssignments({ commit, dispatch, getters }, payload) {
      return new Promise((resolve, reject) => {
        dispatch('fetchUserRoleAssignments', getters.parsedAccessToken.sub)
          .then(response => {
            commit('setRoleAssignments', response.data._embedded.roleAssignments);
            resolve(response);
          })
          .catch(reject);
      });
    },
    fetchUserRoleAssignments({ commit, state }, payload) {
      return new Promise((resolve, reject) => {
        axios({
          method: 'get',
          url: `/environments/${state.environmentId}/users/${payload}/roleAssignments?expand=role`,
          baseURL: state.apiUrl,
          headers: {
            'Authorization': `Bearer ${state.userTokens.access_token}`,
            'Content-Type': 'application/json',
          },
          withCredentials: false
        })
          .then(resolve)
          .catch(reject);
      })
    },
    fetchUsers({ commit, state }, payload) {
      return new Promise((resolve, reject) => {
        axios({
          method: 'get',
          url: `/environments/${state.environmentId}/users${payload}`,
          baseURL: state.apiUrl,
          headers: {
            'Authorization': `Bearer ${state.userTokens.access_token}`,
            'Content-Type': 'application/json',
          },
          withCredentials: false
        })
          .then(resolve)
          .catch(reject);
      })
    },
    fetchUsersBatch({ state, commit, dispatch }, payload) {
      return new Promise((resolve, reject) => {
        dispatch('fetchUsers', payload)
          .then((response) => {
            const batch = [
              ...state.tempUserBatch,
              ...response.data._embedded.users,
            ]
            commit('setTempUserBatch', batch)
            if (response.data._links?.next?.href) {
              const newPayload = (response.data._links.next.href).split("/users")[1]
              if (newPayload) {
                return new Promise((resolve, reject) => {
                  dispatch('fetchUsersBatch', newPayload)
                    .then(response => {
                      resolve(response)
                    })
                    .catch(reject)
                })
              }
            }
          })
          .then(response => {
            resolve(response)
          })
          .catch(reject)
      })
    },
    fetchIDAUsers({ state, commit, dispatch, rootGetters }) {
      let payload = '?limit=1000'
      const clientSelected = rootGetters['admin/getGlobalClientSelected']
      if (clientSelected !== 'All') {
        payload = payload + '&filter=population.id eq "' + clientSelected + '"'
      }

      return new Promise((resolve, reject) => {
        dispatch('fetchUsersBatch', payload)
          .then((response) => {
            commit('setIDAUsers', state.tempUserBatch)
            commit('setTempUserBatch', [])
            resolve(response)
          })
          .catch(reject)
      })
    },
    fetchRoles({ commit, state }) {
      return new Promise((resolve, reject) => {
        axios({
          method: 'get',
          url: `/roles`,
          baseURL: state.apiUrl,
          headers: {
            'Authorization': `Bearer ${state.userTokens.access_token}`,
            'Content-Type': 'application/json',
          },
          withCredentials: false
        })
          .then(response => {
            commit('setRoles', response.data._embedded.roles);
            resolve(response);
          })
          .catch(reject);
      })
    },
    fetchPopulations({ commit, state }) {
      return new Promise((resolve, reject) => {
        axios({
          method: 'get',
          url: `/environments/${state.environmentId}/populations`,
          baseURL: state.apiUrl,
          headers: {
            'Authorization': `Bearer ${state.userTokens.access_token}`,
            'Content-Type': 'application/json',
          },
          withCredentials: false
        })
          .then(response => {
            commit('setPopulations', response.data._embedded.populations);
            resolve(response);
          })
          .catch(reject);
      })
    },
    createUserRoleAssignment({ state }, payload) {
      return new Promise((resolve, reject) => {
        axios({
          method: 'post',
          url: `/environments/${state.environmentId}/users/${payload.userId}/roleAssignments`,
          baseURL: state.apiUrl,
          headers: {
            'Authorization': `Bearer ${state.userTokens.access_token}`,
            'Content-Type': 'application/json',
          },
          data: {
            ...payload.data
          },
          withCredentials: false
        })
          .then(resolve)
          .catch(reject);
      })
    },
    createUserIDARole({ getters, dispatch }, payload) {
      const { userId, type, id } = payload;
      return dispatch('createUserRoleAssignment', {
        userId,
        data: {
          role: {
            id: getters.IDARoleID
          },
          scope: {
            type,
            id
          }
        }
      });
    },
    deleteUserRoleAssignment({ state }, payload) {
      return new Promise((resolve, reject) => {
        axios({
          method: 'delete',
          url: `/environments/${state.environmentId}/users/${payload.userId}/roleAssignments/${payload.roleAssignmentId}`,
          baseURL: state.apiUrl,
          headers: {
            'Authorization': `Bearer ${state.userTokens.access_token}`,
            'Content-Type': 'application/json',
          },
          withCredentials: false
        })
          .then(resolve)
          .catch(reject);
      })
    },
  },
  getters: {
    parsedAccessToken(state) {
      if (state.userTokens.access_token) {
        const base64Url = state.userTokens.access_token.split('.')[1];
        const base64 = base64Url.replace('-', '+').replace('_', '/');
        return JSON.parse(window.atob(base64));
      }
    },
    parsedIdToken(state) {
      if (state.userTokens.id_token) {
        const base64Url = state.userTokens.id_token.split('.')[1];
        const base64 = base64Url.replace('-', '+').replace('_', '/');
        return JSON.parse(window.atob(base64));
      }
    },
    parsedRefreshToken(state) {
      if (state.userTokens.refresh_token) {
        const base64Url = state.userTokens.refresh_token.split('.')[1];
        const base64 = base64Url.replace('-', '+').replace('_', '/');
        return JSON.parse(window.atob(base64));
      }
    },
    policy(state) {
      return state.config.policy ? state.config.policy : 'Customer-Care';
    },
    isAdmin(state, getters) {
      return getters.parsedIdToken?.profile?.role === 'admin';
    },
    isAccountAdmin(state, getters) {
      return getters.parsedIdToken?.profile?.role === 'accountadmin';
    },
    isClientAdmin(state, getters) {
      return getters.parsedIdToken?.profile?.role === 'clientadmin';
    },
    userRole(state, getters) {
      return getters.parsedIdToken?.profile?.role;
    },
    manageableAccounts(state, getters, rootState, rootGetters) {
      return getters.isAccountAdmin && getters.parsedIdToken?.profile?.managingAccounts
        ? rootGetters['ocapi/allCustomerGroups']?.filter(group => getters.parsedIdToken?.profile?.managingAccounts?.includes(group.id)) || []
        : [];
    },
    manageableUsers(state, getters) {
      return getters.isAdmin
        ? state.IDAUsers.filter(user => user.id !== getters.parsedAccessToken.sub)
        : state.IDAUsers.filter(user => (user?.profile?.role !== 'admin' && user?.profile?.role !== 'accountadmin') && user.id !== getters.parsedAccessToken.sub);
    },
    reportingUsers(state, getters) {
      return state.IDAUsers
        .filter(user => getters.parsedIdToken?.profile?.reportingUsers?.indexOf(user.email) !== -1 || user.email === getters.parsedIdToken.email);
    },
    IDARoleID(state) {
      return state.roles.find(role => role.description === 'Identity Data Admin')?.id;
    },
    getIDARoleAssignments: (state, getters) => (roleAssignments) => {
      return roleAssignments.filter(assignment => assignment.role.id === getters.IDARoleID);
    },
    currentUserIDARoleAssignments(state, getters) {
      return getters.getIDARoleAssignments(state.roleAssignments);
    },
    getRoleAssignmentPopulationIds: () => (roleAssignments) => {
      return roleAssignments
        .filter(assignment => assignment.scope.type.toLowerCase() === 'population')
        .map(assignment => assignment.scope.id);
    },
    currentUserIDARoleAssignmentPopulationIds(state, getters) {
      return getters.getRoleAssignmentPopulationIds(getters.currentUserIDARoleAssignments);
    },
    manageablePopulations(state, getters) {
      return state.populations
        .filter(population => getters.currentUserIDARoleAssignmentPopulationIds.indexOf(population.id) !== -1);
    }
  }
};
