import moment from 'moment-timezone'
import axios from 'axios';
export default {
  namespaced: true,
  state: {
    klaviyoSiteId: process.env.VUE_APP_KLAVIYO_SITE_ID,
    klaviyoApiUrl: process.env.VUE_APP_KLAVIYO_API_URL,
    proxyApiUrl: process.env.VUE_APP_PROXY_API_URL,
    loading: false,
    customerNoList: [],
    roles: [
      {
        value: 'admin',
        label: 'HBC (Super) Admin'
      },
      {
        value: 'accountadmin',
        label: 'HBC Account Admin'
      },
      {
        value: 'clientadmin',
        label: 'Client Admin'
      },
      {
        value: 'reporting',
        label: 'Manager'
      },
      {
        value: 'orderer',
        label: 'Orderer'
      }
    ],
    summaryReportData: [],
    globalClientSelected: 'All',
    globalDateTypeSelected: 'creation_date',
    globalYear: {
      year: moment().year(),
      fromDate: moment({ year: moment().year() }).local().startOf('year').startOf('day'),
      toDate: moment({ year: moment().year() }).local().endOf('year').endOf('day')
    },
    globalDateRange: {
      label: 'last7Days',
      fromDate: moment().local().subtract(7, 'days').startOf('day'),
      toDate: moment().local().endOf('day'),
    },
    globalTrendDateRange: {
      label: 'currentMonth',
      fromDate: moment().local().startOf('month').startOf('day'),
      toDate: moment().local().endOf('month').endOf('day'),
    },
    globalTrendDateRange2: {
      label: 'lastMonth',
      fromDate: moment().local().subtract(1, 'month').startOf('month').startOf('day'),
      toDate: moment().local().subtract(1, 'month').endOf('month').endOf('day'),
    },
    orderedProductsDetails: [],
    orderedProductsDetails2: [],
    activeUsersCount: 0,
    activeUsersCount2: 0,
    categoriesListDetails: [],
    productCategoryMapping: [],
    tempOrderBatch: [],
    tempOrderBatch2: [],
  },
  mutations: {
    startLoading(state) {
      state.loading = true
    },
    stopLoading(state) {
      state.loading = false
    },
    setSelectedSiteId(state, payload) {
      state.selectedSiteId = payload
    },
    setCustomerNoList(state, payload) {
      state.customerNoList = payload
    },
    setSummaryReportData(state, payload) {
      state.summaryReportData = payload
    },
    setGlobalClientSelected(state, payload) {
      state.globalClientSelected = payload
    },
    setGlobalDateTypeSelected(state, payload) {
      state.globalDateTypeSelected = payload
    },
    setGlobalYear(state, payload) {
      state.globalYear = payload
    },
    setGlobalDateRange(state, payload) {
      state.globalDateRange = payload
    },
    setGlobalTrendDateRange(state, payload) {
      state.globalTrendDateRange = payload
    },
    setGlobalTrendDateRange2(state, payload) {
      state.globalTrendDateRange2 = payload
    },
    setCategoriesListDetails(state, payload) {
      state.categoriesListDetails = payload
    },
    setProductCategoryMapping(state, payload) {
      state.productCategoryMapping = payload
    },
    setOrderedProductsDetails(state, payload) {
      state.orderedProductsDetails = payload
    },
    setOrderedProductsDetails2(state, payload) {
      state.orderedProductsDetails2 = payload
    },
    setActiveUsersCount(state, payload) {
      state.activeUsersCount = payload
    },
    setActiveUsersCount2(state, payload) {
      state.activeUsersCount2 = payload
    },
    setTempOrderBatch(state, payload) {
      state.tempOrderBatch = payload
    },
    setTempOrderBatch2(state, payload) {
      state.tempOrderBatch2 = payload
    },
  },
  actions: {
    init({ rootGetters, dispatch }) {
      return new Promise((resolve, reject) => {
        dispatch('ocapi/fetchCurrentUserCustomerGroup', null, { root: true })
          .then(() => dispatch('ocapi/fetchAllCustomerGroups', null, { root: true }))
          .then(resolve)
          .catch(reject);
      })
    },
    resolveCustomer({ rootState, commit, rootGetters, dispatch }) {
      return new Promise((resolve, reject) => {
        // check if ping user is existing customer in ocapi
        dispatch('fetchOcapiLoginForCurrentPingUser')
          .then(() => {
            // if they exist, log them in
            if (rootState.ocapi.login) {
              const inactiveAccountExpiryAfterDays = rootGetters.getClientConfig?.inactiveAccountExpiryAfterDays || 120;
              const allowedLastLogin = new Date();
              allowedLastLogin.setDate(allowedLastLogin.getDate() - inactiveAccountExpiryAfterDays);

              const error = {
                locked: true
              }
              const locked = rootGetters['ping/parsedIdToken'].locked;
              const checkLock = (process.env.VUE_APP_ENV === 'prod' || process.env.VUE_APP_ENV === 'stage')
              if (checkLock && locked?.status) { // Already locked
                reject(error);
              } else if (checkLock && rootState.ocapi.lastLogin.getTime() < allowedLastLogin.getTime()) { // First time check
                dispatch('ping/lockUser', null, { root: true })
                reject(error);
              /* Ocapi last login updates after the first time check, so have commented below section it has more conditions to include
              } else if (!locked?.status && ((new Date(locked?.lockedOn)).getTime() > (new Date(locked?.unlockedOn)).getTime())) {
                dispatch('ping/lockUser', null, { root: true })
                reject(error);
              */
              } else {
                return dispatch('ocapi/fetchUserAuthWithToken', null, { root: true })
              }
            // otherwise
            // - create user in OCAPI
            // - log them in
            } else {
              return dispatch('ocapi/fetchGuestAuth', null, { root: true })
                .then(() => dispatch('ocapi/createCustomerExtProfile', null, { root: true }))
                .then(() => dispatch('ocapi/fetchCustomer', rootState.ocapi.customerExtProfile.customer_id, { root: true }))
                .then(() => {
                  commit('ocapi/setCurrentUserLogin', rootState.ocapi.customerExtProfile.customer_id, { root: true });
                  return dispatch('ocapi/fetchUserAuthWithToken', null, { root: true })
                })
            }
          })
          // update OCAPI customer with latest Ping settings
          .then(() => dispatch('ocapi/updateCustomer', {
            c_pingPopulationID: rootGetters['ping/parsedIdToken'].populationID,
            first_name: rootGetters['ping/parsedIdToken'].given_name,
            last_name: rootGetters['ping/parsedIdToken'].family_name,
            email: rootGetters['ping/parsedIdToken'].email,
          }, { root: true }))
          .then(() => {
            // if (rootGetters['ping/parsedIdToken']?.userData?.languages.length === 1) {
            //   commit('setActiveLanguage', rootGetters['ping/parsedIdToken'].userData.languages[0]);
            // }
            return dispatch('ocapi/fetchCurrentUserCustomerGroup', null, { root: true })
          })
          .then(() => {
            if (!rootState.ocapi.customerGroup) {
              // eslint-disable-next-line prefer-promise-reject-errors
              reject();
            } else {
              resolve();
            }
          })
          .catch(reject);
      });
    },
    fetchOcapiLoginForCurrentPingUser({ dispatch, commit, rootGetters }) {
      return new Promise((resolve, reject) => {
        dispatch('ocapi/searchCustomers', {
          query: {
            text_query: {
              fields: [
                'email'
              ],
              search_phrase: rootGetters['ping/parsedIdToken'].email
            }
          },
          select: '(**)'
        }, { root: true })
          .then(response => {
            if (response.data.count > 0) {
              const login = response.data.hits[0].data.credentials.login;
              const lastLogin = response.data.hits[0].data.last_login_time && response.data.hits[0].data.last_login_time !== 'Never' ? new Date(response.data.hits[0].data.last_login_time) : new Date();
              commit('ocapi/setCurrentUserLogin', login, { root: true });
              commit('ocapi/setLastLogin', lastLogin, { root: true });
              resolve(login);
            } else {
              resolve(null);
            }
          })
          .catch(reject);
      })
    },

    // Get Categories List Details two levels from Root Category - Category Id and Name
    fetchCategoriesListDetails({ commit, dispatch }) {
      /*eslint-disable*/
      const payload = {
        category_id: 'root',
        params: {
          levels: 2
        }
      };
      /* eslint-enable */
      return new Promise((resolve, reject) => {
        dispatch('ocapi/fetchCategories', payload, { root: true })
          .then((response) => {
            commit('setCategoriesListDetails', response.data.categories)
            resolve(response)
          })
          .catch(reject)
      })
    },

    // Get Product List Details for provided Categories - Product Id
    fetchProductListForCategories({ commit, dispatch }, payload) {
      // const siteId = state.selectedSiteId || state.dataApiDefaultSiteId;
      return new Promise((resolve, reject) => {
        dispatch('ocapi/fetchMultipleProducts', payload, { root: true })
          .then((response) => {
            commit('setProductCategoryMapping', response.data.data)
            resolve(response)
          })
          .catch(reject)
      })
    },

    // Get Ordered Product List Details - Product Id and Ordered Quantity
    fetchOrderedProductDetails({ state, commit, dispatch }, payload) {
      // commit('startLoading')
      const { dateType, fromDate, toDate, selectFilter, tempBatchObj2 } = payload
      const chunkSize = 200
      return new Promise((resolve, reject) => {
        dispatch('fetchOrderedProductDetailsBatch', {
          initial: true,
          count: chunkSize,
          start: 0,
          dateType,
          fromDate,
          toDate,
          selectFilter,
          tempBatchObj2,
        })
          .then(response => {
            const total = response.data.total
            const remaining = []
            for (let i = chunkSize; i < total; i += chunkSize) {
              remaining.push(dispatch('fetchOrderedProductDetailsBatch', {
                count: chunkSize,
                start: i,
                dateType,
                fromDate,
                toDate,
                selectFilter,
                tempBatchObj2,
              }))
            }
            return Promise.all(remaining)
          })
          .then((response) => {
            if (payload.tempBatchObj2) {
              commit(payload.saveToStateObj, state.tempOrderBatch2)
              commit('setTempOrderBatch2', [])
            } else {
              commit(payload.saveToStateObj, state.tempOrderBatch)
              commit('setTempOrderBatch', [])
            }
            // commit('stopLoading')
            resolve(response)
          })
          .catch((error) => {
            // commit('stopLoading')
            reject(error)
          })
      })
    },

    // Batch Order Search due to OCAPI return limit - only 200 orders per call
    fetchOrderedProductDetailsBatch({ state, commit, rootGetters, dispatch }, payload) {
      /*eslint-disable*/
      
      let customerFilter = { match_all_query: {} }
      let clientFilter = { match_all_query: {} }

      if (rootGetters['ping/isAdmin'] || rootGetters['ping/isAccountAdmin']) {
        if (state.globalClientSelected != 'All') {
          clientFilter = {
            term_query: {
              fields: ["c_clientID"],
              operator: "is",
              values: [state.globalClientSelected]
            }
          }
        }
      } else {
        if (rootGetters['ping/parsedIdToken']?.populationID?.length > 0) {
          clientFilter = {
            term_query: {
              fields: ["c_clientID"],
              operator: "is",
              values: [rootGetters['ping/parsedIdToken'].populationID]
            }
          }
        }
        if (rootGetters['ping/parsedIdToken']?.profile?.reportingUsers?.length > 0) {
          customerFilter = {
            term_query: {
              fields: ["customer_email"], // customer_email, customer_no
              operator: "one_of",
              values: rootGetters['ping/parsedIdToken'].profile.reportingUsers
            }
          }
        }
      }

      let statusFilter = {
        term_query: {
          fields: ["status"],
          operator: "one_of",
          values: ["created", "new", "open", "completed"]
        }
      }

      const newPayload = {
        count: payload.count || 200, // default is 25 only
        start: payload.start,
        query: {
          filtered_query: {
            query: {
              bool_query: {
                must : [
                  statusFilter, customerFilter, clientFilter
                ]
              }
            },
            filter: {
              range_filter: {
                field: payload.dateType, // creation_date / c_shippedDate
                from: moment(payload.fromDate).tz('America/Chicago', true).startOf('day'),
                to: moment(payload.toDate).tz('America/Chicago', true).endOf('day'),
              }
            }
          }
        },
        select: payload.selectFilter,
        sorts: [
          {
            field: "creation_date",
            sort_order:"asc"
          }
        ]
      }
      /* eslint-enable */
      return new Promise((resolve, reject) => {
        dispatch('ocapi/fetchOrders', newPayload, { root: true })
          .then(response => {
            const responseData = response.data.hits || []
            if (payload.initial) {
              if (payload.tempBatchObj2) {
                commit('setTempOrderBatch2', responseData)
              } else {
                commit('setTempOrderBatch', responseData)
              }
            } else {
              if (payload.tempBatchObj2) {
                const batch = [
                  ...state.tempOrderBatch2,
                  ...responseData,
                ]
                commit('setTempOrderBatch2', batch)
              } else {
                const batch = [
                  ...state.tempOrderBatch,
                  ...responseData,
                ]
                commit('setTempOrderBatch', batch)
              }
            }
            resolve(response)
          })
          .catch(reject)
      })
    },
    sendNewUserEmail({ state }, payload) {
      return new Promise((resolve, reject) => {
        axios({
          method: 'post',
          url: '/client/events/?company_id=' + state.klaviyoSiteId,
          baseURL: state.klaviyoApiUrl,
          withCredentials: false,
          headers: {
            revision: '2022-10-17',
            contentType: 'application/json',
            accept: 'application/json'
          },
          data: {
            data: {
              type: 'event',
              attributes: {
                profile: {
                  $email: payload.email,
                  $first_name: payload.firstName,
                  $last_name: payload.lastName,
                  organization: payload.clientName
                },
                metric: {
                  name: 'Account Registration'
                },
                properties: {
                  firstName: payload.firstName,
                  email: payload.email,
                  tempPassword: payload.tempPassword,
                  url: payload.url,
                  clientName: payload.clientName
                },
                time: new Date().toISOString()
              }
            }
          }
        })
          .then(resolve)
          .catch(reject);
      })
    },
    fetchEnabledUsersCount({ commit, dispatch }, payload) {
      return new Promise((resolve, reject) => {
        dispatch('ping/fetchUsers', payload.filter, { root: true })
          .then(response => {
            commit(payload.saveToStateObj, response.data.count || 0);
            resolve(response);
          })
          .catch(reject)
      })
    },

    updateGlobalClientSelected({ commit }, payload) {
      commit('setGlobalClientSelected', payload)
    },
    updateGlobalDateTypeSelected({ commit }, payload) {
      commit('setGlobalDateTypeSelected', payload)
    },
    updateGlobalYear({ commit }, payload) {
      commit('setGlobalYear', payload)
    },
    updateDateRange({ commit }, payload) {
      commit('setGlobalDateRange', payload)
    },
    updateGlobalTrendDateRange({ commit }, payload) {
      commit('setGlobalTrendDateRange', payload)
    },
    updateGlobalTrendDateRange2({ commit }, payload) {
      commit('setGlobalTrendDateRange2', payload)
    },
  },
  getters: {
    getLocations: () => (clientConfig) => {
      return clientConfig?.locations || [];
    },
    getLabel: (state, getters) => (value, items) => {
      if (!value) {
        return '';
      }
      return items.find(item => item.value === value)?.label || '';
    },
    locations(state, getters, rootState, rootGetters) {
      return getters.getLocations(rootGetters['ocapi/clientConfig']);
    },
    getRoleLabel: (state, getters) => (value) => {
      if (!value) {
        return '';
      }
      const matchingRole = state.roles.filter(role => role.value === value);
      if (matchingRole.length) {
        return matchingRole[0].label;
      }
      return '';
    },
    clientMinimum(state, getters, rootState, rootGetters) {
      const custGroups = rootGetters['ocapi/allCustomerGroups'] || []

      const selectedGroup = custGroups.filter(group => group.id === state.globalClientSelected)
      if (selectedGroup.length) {
        return selectedGroup[0].clientMinimum || {}
      }
      return {}
    },
    getGlobalClientSelected(state) {
      return state.globalClientSelected
    }
  }
}
