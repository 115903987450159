import Vue from 'vue'
import Vuex from 'vuex'
import user from './user'
import settings from './settings'
import admin from './admin'
import ocapi from './ocapi'
import ping from './ping'
import router from '../router'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    user,
    settings,
    admin,
    ocapi,
    ping,
  },
  state: {
    loading: true,
  },
  mutations: {
    startLoading(state) {
      state.loading = true;
    },
    stopLoading(state) {
      state.loading = false
    },
    setError(state, payload) {
      state.error = payload;
    }
  },
  actions: {
    handleError({ commit }, payload) {
      commit('setError', payload);
      router.push('error');
    }
  }
})
