<template>
  <div class="layout-login d-flex flex-column" :style="styles">
    <b-container class="my-auto">
      <b-row class="justify-content-center">
        <b-col md="7" lg="5">
          <div class="p-5 bg-white login-actions">
            <div class="text-center mb-4">
              <img :src="`/images/logos/${config.logo}`" class="d-block mx-auto img-fluid" />
            </div>

            <router-view />

            <p class="text-center mt-4"><small>Need some help? No problem. Email us at <a href="mailto:care.help@hallmarkbusinessconnections.com">care.help@hallmarkbusinessconnections.com</a>.</small></p>
          </div>
        </b-col>
      </b-row>
    </b-container>

    <footer class="login-footer bg-dark py-4">
      <b-container>
        <b-row align-v="center">
          <b-col cols="12" lg="3">
            <img src="/images/logos/hbc-logo-white-small.png" class="d-block img-fluid" alt="Hallmark Business Connections">
          </b-col>
          <b-col class="text-center text-muted" cols="12" lg="6">
            <p class="mb-0">&copy; Hallmark Business Connections. All rights reserved.</p>
            <p class="mb-0"><a href="mailto:Care.help@hallmarkbusinessconnections.com">Care.help@hallmarkbusinessconnections.com</a></p>
          </b-col>
          <b-col cols="12" lg="3">
            <div class="d-flex justify-content-center justify-content-lg-end w-100 text-muted">
              <a href="http://www.hallmarkbusinessconnections.com/privacy-security-policies" target="_blank">Privacy &amp; Security</a>
              <a href="http://www.hallmarkbusinessconnections.com/" target="_blank" class="ml-4">Company</a>
            </div>
          </b-col>
        </b-row>
      </b-container>
    </footer>
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex';

export default {
  computed: {
    ...mapState('ping', [
      'config'
    ]),
    styles() {
      let styles = '';
      if (this.config.background_image) {
        styles += `background-image:url('/images/${this.config.background_image}');`;
      }
      if (this.config.primary_color) {
        styles += `--loginprimary: ${this.config.primary_color};`;
      }
      if (this.config.primary_color_hover) {
        styles += `--loginprimaryhover: ${this.config.primary_color_hover};`;
      } else if (this.config.primary_color) {
        styles += `--loginprimaryhover: ${this.config.primary_color};`;
      }
      return styles;
    }
  },
  methods: {
    ...mapMutations(['SET_THEME'])
  },
  mounted() {
    this.SET_THEME({ theme: 'default' });
  }
}
</script>
