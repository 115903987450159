<template>
  <div :class="$style.topbar">
    <div class="w-50 cui__utils__heading mb-0 mr-auto">
      <strong>Reporting Dashboard</strong>
    </div>
    <!--<div class="mr-4">
      <cui-fav-pages />
    </div>
    <div class="mr-auto">
      <cui-search />
    </div>
    <div class="mr-4 d-none d-md-block">
      <cui-issues-history />
    </div>
    <div class="mb-0 mr-auto d-xl-block d-none">
      <cui-project-management />
    </div>
    <div class="mr-4 d-none d-sm-block">
      <cui-language-switcher />
    </div>
    <div class="mr-4 d-none d-sm-block">
      <cui-actions />
    </div>-->
    <div class="mr-4 d-none d-sm-block">
      <client-selector />
    </div>
    <div class="mr-4">
      <cui-user-menu />
    </div>
    <div>
      <cui-theme-switch />
    </div>
  </div>
</template>

<script>
// import CuiIssuesHistory from './IssuesHistory'
// import CuiProjectManagement from './ProjectManagement'
// import CuiSearch from './Search'
// import CuiLanguageSwitcher from './LanguageSwitcher'
// import CuiActions from './Actions'
import CuiUserMenu from './UserMenu'
// import CuiFavPages from './FavPages'
import CuiThemeSwitch from './ThemeSwitch'
import ClientSelector from './ClientSelector'

export default {
  components: {
    CuiUserMenu,
    CuiThemeSwitch,
    ClientSelector,
  }, // CuiIssuesHistory, CuiProjectManagement, CuiSearch, CuiFavPages, CuiLanguageSwitcher, CuiActions,
}
</script>

<style lang="scss" module>
@import "./style.module.scss";
</style>
