<template>
  <div id="app">
    <div :class="{ 'hidden': loading}">
      <localization></localization>
    </div>
    <div class="overlay" v-if="loading || adminLoading">
      <div class="spinner-wrapper">
        <b-spinner variant="info" style="width:3rem; height:3rem;"></b-spinner>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import Localization from '@/localization'

export default {
  name: 'app',
  components: { Localization },
  data() {
    return {
      authCheck: null
    }
  },
  computed: {
    ...mapState(['settings']),
    ...mapState(['loading']),
    ...mapState('admin', { adminLoading: 'loading' }),
    ...mapState('user', ['authorized']),
    ...mapState('ping', ['authenticated']),
    nextRoute() {
      return this.$route.query.redirect || '/'
    },
    currentRoute() {
      return this.$route.path
    },
  },
  methods: {
    ...mapActions('ping', [
      'evaluateTokens'
    ])
  },
  mounted() {
    this.$store.dispatch('user/LOAD_CURRENT_ACCOUNT')
    this.$store.commit('SET_PRIMARY_COLOR', { color: this.settings.primaryColor })
    this.$store.commit('SET_THEME', { theme: this.settings.theme })
    this.authCheck = setInterval(() => {
      if (this.authenticated) {
        this.evaluateTokens()
      }
    }, 1000 * 60);
  },
  watch: {
    '$store.state.settings.theme'(theme) {
      this.$store.commit('SET_THEME', { theme })
    },
    authorized(authorized) {
      if (authorized && this.currentRoute === '/auth/login') {
        this.$router.replace(this.nextRoute)
      }
    },
    '$route'(to, from) {
      const query = Object.assign({}, to.query)
      this.$store.commit('SETUP_URL_SETTINGS', query)
    },
  },
  beforeDestroy() {
    clearInterval(this.authCheck);
  }
}
</script>

<style lang="scss">
.hidden {
  opacity: 0;
}
.overlay {
  position:fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(#fff, .5);
  z-index: 1;
  .spinner-wrapper {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}
</style>
